@font-face {
    font-family: 'Gotham';
    src: url("../assets/fonts/Gotham-Book.eot");
    src: url("../assets/fonts/Gotham-Book.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Gotham-Book.woff") format("woff"), url("../assets/fonts/Gotham-Book.ttf") format("truetype");
    font-weight: normal;
    font-style: normal; }
@font-face {
    font-family: 'Gotham';
    src: url("../assets/fonts/Gotham-Light.eot");
    src: url("../assets/fonts/Gotham-Light.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Gotham-Light.woff") format("woff"), url("../assets/fonts/Gotham-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal; }
@font-face {
    font-family: 'Gotham';
    src: url("../assets/fonts/Gotham-Medium.eot");
    src: url("../assets/fonts/Gotham-Medium.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Gotham-Medium.woff") format("woff"), url("../assets/fonts/Gotham-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal; }
@font-face {
    font-family: 'Gotham';
    src: url("../assets/fonts/Gotham-Bold.eot");
    src: url("../assets/fonts/Gotham-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Gotham-Bold.woff") format("woff"), url("../assets/fonts/Gotham-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal; }

