h1{
    margin-bottom: 0;
    margin-top: 30px;
    @include media-breakpoint-down(lg) {
        font-size: 2.2rem;
    }
    @include media-breakpoint-down(md) {
        font-size: 2rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
    }
}

.page{

    padding: 100px 0;
    @include media-breakpoint-down(lg) {
        padding: 70px 0;
    }
    @include media-breakpoint-down(md) {
        padding: 50px 0;
    }
    @include media-breakpoint-down(sm) {
        padding: 30px 0;
    }
    ul{
        list-style: none;
        padding-left: 20px;
        li{
            position: relative;
            padding-left: 20px;
            &:before{
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 8px;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                background: $red;
            }
        }
    }

   
    
    h2{
        font-weight: 500;
        color: $darkblue;
        margin-bottom: 20px;
        text-align: center;
        font-size: 2.5rem;
        @include media-breakpoint-down(lg) {
            font-size: 2rem;
        }
        @include media-breakpoint-down(md) {
            font-size: 1.6rem;
        }
        @include media-breakpoint-down(sm) {
            font-size: 1.4rem;
        }
    }

    &.gallery-page{
        padding-top: 30px;
    }

    &.services{
        padding: 50px 0;
       
        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }
        @include media-breakpoint-down(sm) {
            padding: 20px 0;
        }
        .content{
            margin-bottom: 80px;
            @include media-breakpoint-down(md) {
                margin-bottom: 50px;
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 40px;
            }
        }
    }

    &.contact{
        h2{
            color: $gold;
            margin-top: 10px;
            .blue{
                color: $darkblue;
            }
        }


        form{
            margin-bottom: 50px;
            label{
                text-align: right;
                display: block;
                flex-basis: 100%;
                max-width: 100%;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @include media-breakpoint-down(sm) {
                    justify-content: flex-start;
                }

                &.label-left{
                    justify-content: flex-start;
                }
            }

            .form-group{
                margin-bottom: 5px;
                
            }
            .form-control{
                background: #e4e4e4;
                border: 0px;
                border-radius: 0px;
                border: 0px!important;
                box-shadow: none!important;
                outline: none!important;
            }
        }
    
    }


}
