$darkblue: #00243b!default;
$gray: #666!default;
$purple: #480841;
$red: #d9c9a0;
$gold: #d9c9a0;

@import "bootstrap";
@import "fonts";
@import "header";
@import "global";
@import "home";
@import "page";
@import "footer";        




body{
    font-family: 'Gotham';
    line-height: 1.5;
    color: #565457;
    
}

.row{
    &:before, &:after{
        display: flex;
    }
}


.gallery{
    padding-top: 130px;
    @media (max-width: 991px){
   
        padding-top: 81px;
    }
    .gallery-item{
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid $gold;
        h2{
            text-align: left;
            margin-top: 30px;
        }
        img{
            max-width: 100%;
            
        }
    }
}

hr{
    border-top: 1px solid $red;
    margin: 30px 0;
    @include media-breakpoint-down(md) {
        margin: 20px 0;
    }
    @include media-breakpoint-down(sm) {
        margin: 10px 0;
    }
}

a{
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;    
    -o-transition: all 0.3s; 
    transition: all 0.3s;
    
    color: $red;
    
    &:hover{
        color: $purple ;
        text-decoration: none;
    }
}

.font-bold{
    font-weight: bold!important;
}
.font-normal{
    font-weight: 400!important;
}
.font-light{
    font-weight: 300!important;
}
.font-medium{
    font-weight: 500!important;
}


.font-eurostile{
    font-family: Eurostile;
}

.font-mont{
    font-family: Montserrat;
}

.green-button{
    padding: 8px 40px;
    background: $green;
    color: #fff;
    font-size: 14px;
    font-weight: bold; 
    font-family: Eurostile;
    display: inline-block;
    border-radius: 20px;

    &:hover, &:focus{
        color: #fff;
        background: $blue; 
        text-decoration: none;
    }
}

.blue-button{
    padding: 8px 40px;
    background: #6a97c9;
    color: #fff;
    font-size: 14px;
    font-weight: bold; 
    font-family: Eurostile;
    display: inline-block;
    border-radius: 20px;

    &:hover, &:focus{
        color: #fff;
        background: $green; 
        text-decoration: none;
    }
}

.main{
    ul{
        padding: 0; 
        list-style: none; 
        li{
            padding-left: 20px;
            position: relative;
            &:after{
                content:'\f111';
                font-family: 'FontAwesome';
                size: 10px;
                position: absolute;
                left: 0;
                top: 0;
                text-align: center;
                width: 20px;
                display: block;
                font-size: 7px;
                line-height: 23px;
                color: $orange;
            }
        }
    }
}




.pagination{
    display: table;
    border: 1px solid #ccc;
    border-radius: 0;
    border-collapse: collapse;
    margin: 30px 0;

    a, strong{
        display: table-cell;
        padding: 5px 10px;
        color: #000;
        vertical-align: middle;
        text-align: center;
        border: 1px solid #ccc;
        &:hover{
            text-decoration: none;
        }
    }

    a{
        &:hover{
            color: $orange;
        }
    }

    strong{
        background: $orange;
        color: #fff;
    }
    
}

