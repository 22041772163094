#footer{
    font-size: 15px;
    .inner{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            flex-direction: column-reverse;
            align-items: center;
            text-align: center;
        }
    }

    img{
        margin-bottom: 30px;
    }
    
    a{
        color: $darkblue;
        &:hover{
            color: #888;
        }
    }
    h4{
        font-weight: bold;
        text-transform: uppercase;
        font-size: 18px;
        margin-bottom: 30px;
    }
    h3{
        font-size: 15px;
        margin-top: 30px;
        font-weight: 500;
    }
    padding: 50px 0;
    background: $gold;
    color: $darkblue;
    @include media-breakpoint-down(sm) {
        padding: 30px 0;
    }
    .phone{
        display: block;
        span{
            font-weight: bold;
            width: 30px;
            display: inline-block;
        }
    }
    .copyright{
        
        font-size: 12px;
    }
}
