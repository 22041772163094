body{
    @include media-breakpoint-down(md) {
        font-size: 15px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
    

    .slider-container{
        padding-top: 130px;
        background: $darkblue;
        height: calc(39vw + 130px);
        position: relative;
        @include media-breakpoint-down(md) {
            
            padding-top: 81px;
            height: calc(39vw + 81px);
        }

        @include media-breakpoint-down(sm) {
            
            padding-top: 81px;
            height: calc(39vw + 131px);
        }

        .loader{
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            color: #fff;
            font-weight: 300;
            font-size: 15px;
            text-align: center;
            padding: 50px;
            padding-top: 130px;
            @include media-breakpoint-down(md) {
            
                padding-top: 81px;
            }
            background: $darkblue;
            opacity: 1;
            z-index: 100;
            visibility: visible;
            transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
            &.hide{
                opacity: 0;
                visibility: hidden;
            }

            img{
                animation: spin 2s linear infinite;
                margin-bottom: 10px;
                @include media-breakpoint-down(md) {
            
                    width: 60px;
                    height: 60px;
                    margin-bottom: 0;
                }
                @include media-breakpoint-down(sm) {
            
                    width: 40px;
                    height: 40px;
                    margin-bottom: 0;
                }

            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg); }
                100% {
                    transform: rotate(360deg); } }
            
        }
    }
    .top-slider{
        
        position: relative;
        z-index: 90;
        position: relative;
        .item{
            background-size: cover;
            background-position: center;
            height: 39vw;
            img{
               display: none;
            }
        }

        .owl-nav{
            position: absolute;
            bottom: 30px;
            right: 100px;
            display: flex;
            z-index: 90;
            margin-top: 0;
            @include media-breakpoint-down(sm) {
            
                position: relative;
                bottom: auto;
                right: auto;
                align-items: center;
                justify-content: center;

            }

            .owl-prev, .owl-next{
                background: none;
                margin: 0 20px;
                border-radius: 50%;
                width: 80px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include media-breakpoint-down(md) {
            
                    width: 50px;
                    height: 50px;
                    margin: 0 10px;
    
                }
               
                img{
                    width: 50px;
                    height: 48px;
                    @include media-breakpoint-down(md) {
            
                        width: 30px;
                        height: 29px;
        
                    }
                    @include media-breakpoint-down(sm) {
            
                        width: 36px;
                        height: 34px;
        
                    }
                }
                &:hover{
                    background: $darkblue;
                }
            }
        }
    }

    .button{
        &.gold{
            color: $darkblue;
            background: $gold;
            display: inline-block;
            padding: 5px 20px;
            font-weight: 500;
            font-size: 15px;
            text-align: center;
            min-width: 200px;
            text-transform: uppercase;

            &:hover, &:focus{
                background: darken($gold, 10%);
                color: #fff;
            }
        }
    }

    .page-header{
        background: $gold;
        
        padding: 70px 0;
        color: $darkblue;
        font-weight: 400;

        padding-top: 200px;
        @include media-breakpoint-down(md) {
            padding: 50px 0;
            padding-top: 131px;
        }
        @include media-breakpoint-down(sm) {
            padding: 30px 0;
            padding-top: 111px;
        }
        h1{
            font-weight: 500;
            color: $darkblue;
            margin-top: 0;
            margin-bottom: 5px;
            @include media-breakpoint-down(lg) {
                font-size: 1.8rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 1.5rem;
            }
            @include media-breakpoint-down(sm) {
                font-size: 1.3rem;
            }

        }
        p{
            margin-bottom: 5px;
            font-size: 18px;
            line-height: 1.2;
            @include media-breakpoint-down(lg) {
                font-size: 17px;
            }
            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 15px;
            }
        }
        a{
            color: $darkblue;
            font-weight: 500;
            &:hover, &:focus{
                color: #888;

            }
        }
    }
}
