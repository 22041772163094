.home{

    h2{
        font-size: 35px;
        font-weight: normal;
        margin-top: 0;
        margin-bottom: 20px;
        
        @include media-breakpoint-down(md) {
                font-size: 30px;
            }
            @include media-breakpoint-down(sm) {
               font-size: 25px;
            }
           
    }
}


