#header{

    background: $darkblue;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    @include media-breakpoint-down(sm) {
            
        padding: 10px 15px;
    }

    .logo{
        img{
            width: 300px;
            height: 90px;
            transition: all 0.3s ease-in-out;

            @include media-breakpoint-down(md) {
            
                width: 200px;
                height: 61px;
            }
           
        }
    }


    .menu-toggler{
        height: 30px;
        
        width: 50px;
        color: #fff;
        z-index: 100;
        border: 0px; 
        position: relative;
        border-radius: 0px;
        .line{
            height: 4px;
            background: $gold;
            display: block;
            width: 100%;
            position: absolute;
            transition: 0.2s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
            &:nth-child(1){
                top: 0;
                left: 0;
                transform: rotate(-45deg) translate3d(-11px, 9px, 0);
                transform-origin: center top;
            }
            &:nth-child(2){
                top: 13px;
                left: 0;
                transform: translate3d(50px, 0, 0);
                opacity: 0;
            }
            &:nth-child(3){
                top: 26px;
                left: 0;
                transform: rotate(45deg) translate3d(-7px, -10px, 0);
                transform-origin: center top;
            }
        }

        -webkit-appearance: none;

        &.collapsed{
            .line{
                &:nth-child(1){
                    top: 0;
                    left: 0;
                    transform: rotate(0) translate3d(0, 0, 0);
                    transform-origin: center top;
                }
                &:nth-child(2){
                    top: 13px;
                    left: 0;
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                }
                &:nth-child(3){
                    top: 26px;
                    left: 0;
                    transform: rotate(0) translate3d(0, 0, 0);
                    transform-origin: center top;
                }
            }
        }

        &:hover{
            .line{
                background: #fff;
               
            }
        }
    }



    #navbar{
        margin-top: 0;
        text-decoration: none;

        transition: padding 0.3s ease-in-out;

        padding: 20px 0;
        @include media-breakpoint-down(md) {
            
            padding: 10px 0;
        }

        @include media-breakpoint-down(sm) {
            
            padding: 0;
        }

        .navbar-collapse{
            flex-basis: auto;
            > .navbar-nav{
                > li{
                    padding: 5px 0;
                    > a{
                        color: #fff;
                        display: block;
                        text-transform: uppercase;
                        padding: 0 15px;
                        font-size: 15px;
                        /* font-family: Eurostile; */
                        letter-spacing: 0px;
                        text-align: center;
                        white-space: nowrap;
                        cursor: pointer;
                       
                        @include media-breakpoint-down(md) {
                            font-size: 12px;
                            padding: 0 8px;
                        }
                        @include media-breakpoint-down(sm) {
                            border: 0!important;
                            font-size: 15px;
                            padding: 5px 20px;
                        }


                    }
                    position: relative;
                    > .sub-menu {
                        display: none;
                        z-index: 10;
                        position: absolute; 
                        background: #f4f4f4;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        @include media-breakpoint-down(sm) {
                            display: none!important;
                        }
                        .sub-menu-item{

                            padding: 7px 10px;
                            font-size: 12px;
                            display: block;
                            color: #666;
                            font-weight: 500;
                            text-align: center;
                            text-transform: uppercase;

                            &:hover, &:focus{
                                background: #908f8f;
                                color: #fff;
                                text-decoration: none;
                            }

                        }


                    }

                    &.has-children{
                        position: relative;

                        @include media-breakpoint-up(md) {
                            &:hover{

                                &:after{
                                    display: block!important;
                                }

                                .sub-menu{
                                    display: block!important;
                                }
                            }
                        }

                    }



                    &:first-child{
                        .nav-link{
                            border-left: 0;
                            @include media-breakpoint-down(sm) {
                                border-top: 1px solid #ddd;
                            }
                        }
                    }

                    
                    &:hover, &:focus{
                        > a{

                            color: $gold;
                        }
                    }


                }
            }



        }

    }
    
    &.sticky{
        #navbar{
            padding: 15px 0;
            @include media-breakpoint-down(md) {
            
                padding: 10px 0;
            }
            @include media-breakpoint-down(sm) {
                padding: 0 0;
            }

            .logo{
            
                img{
                    width: 200px;
                    height: 61px;
                }
               
            }

        }
    }



}

.feather{

    padding: 30px 0 0;
    
    
    @include media-breakpoint-down(sm) {
        img{
            transform: scale(0.7);
            
            transform-origin: left center;
        }
        
        padding: 0;
        
        hr{
            margin-top: 0;
        }
    }
    

}